<template>
<div class="main">
    <router-link class="nav" :to="{name:'Home'}">首页</router-link>

    <div class="login-wrp">
        <h2>注册</h2>
        <el-row>
            <el-col :span="6">
                <label>用户名</label>
            </el-col>
            <el-col :span="18">
                <el-input type="text" placeholder="请输入用户名" v-model="user"></el-input>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <label>手机号码</label>
            </el-col>
            <el-col :span="18">
                <el-input type="text" placeholder="请输入手机号码" v-model="phone"></el-input>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <label>密码</label>
            </el-col>
            <el-col :span="18">
                <el-input type="password" placeholder="请输入密码" v-model="psword" show-password></el-input>
            </el-col>
        </el-row>
        <el-row class="radio-wrp">
            <el-radio v-model="radio" label="1">用户</el-radio>
            <el-radio v-model="radio" label="2">商家</el-radio>
        </el-row>
        <el-row class="login-btns">
            <el-button type="primary" size="medium" @click="register">注册</el-button>
        </el-row>
        <div class="tips">
            注册表示您同意 <router-link to="/infos">网站协议（用户）</router-link>       <router-link to="/infos2">网站协议（商家）</router-link>     
        </div>
    </div>
</div>
</template>

<script>
let app = null
export default {
    data() {
        return {
            user: "",
            psword: "",
            phone: "",
            radio:"1"
        }
    },
    created(){
        app = this.$.appContext.app
    },
    methods: {
        register() {
            app.wpost({
                url: "/Ajax/WordWelfare/register",
                data: {
                    name:this.user,
                    phone:this.phone,
                    password:this.psword
                },
                succ: (data) => {
                    if(data.result == "success"){
                            this.$message(data.result)
                            return false
                        
                    this.$message("注册成功")
                    this.$router.push({path:"/login"})
                    }
                },
            });
        }
    }
}
</script>

<style scoped>
.main {
    padding-top: 27vh;
    background-image: url("../assets/join.jpg");
    background-position: center center;
    background-size: auto 100%;
    height: calc(100vh - 18px);
    box-sizing: border-box;
}
.radio-wrp{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav {
    position: absolute;
    top: 22px;
    right: 22px;
    color: #999;
}
.tips{
    font-size: 12px;
    color: #666;
}
.tips a{
    text-decoration: underline;
    color: skyblue;
    margin-left: 3px;
}
.login-wrp {
    width: 50%;
    padding: 10px 20px;
    box-sizing: border-box;
    min-width: 300px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 0 0 12px #eee;
}

.login-wrp .el-row {
    line-height: 40px;
    margin-bottom: 20px;
}

.login-btns .el-button {
    margin: 0 auto;
}
</style>
